<!--
 * @Author: sqk
 * @Date: 2020-08-25 15:27:33
 * @LastEditTime: 2020-08-25 17:57:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\historyList\index.vue
-->

<template>
  <div>
    <a-button type="primary" v-print="'#printMe'">打印预览</a-button>
    <a-table id="printMe" :columns="columns" :data-source="data" :pagination="pagination">
        <template slot="operation" slot-scope="text, record">
            <a href="javascript:;" @click="openPageFun(record.path)">查看</a>
        </template>
    </a-table>
  </div>
</template>
<script>
//接口
import {
  getHistoryList, //获取历史纪录列表
} from "@/views/historyList/api";
export default {
  name: "histotyList",
  data() {
    return {
    pagination: {
        total: 0,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
        // itemRender(current, type, originalElement) {
        //     console.log(current)
        //     console.log(type)
        //     console.log(originalElement)
        //     if (type === 'prev') {
        //         return <a class="ant-pagination-item itxst">上一页</a>;
        //     } else if (type === 'next') {
        //         return <a class="ant-pagination-item itxst">下一页</a>;
        //     }
        //     return originalElement;
        // }
      },
      data: [
        {
          key: "1",
          name: "John Brown",
          path: "New York No. 1 Lake Park",
          time: "2020/08/25"
        }
      ],
      columns: [
        {
          title: "浏览记录",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "路径",
          dataIndex: "path",
          key: "path"
        },
        {
          title: "浏览时间",
          dataIndex: "time",
          key: "time"
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ]
    };
  },
  mounted: function() {
    this.data = this.$store.state.historyList.list;
    let para = {
    };
    getHistoryList(para)
    .then(res => {
        //返回data 假数据
        console.log(res)
        this.data = this.$store.state.historyList.list;
    })
    .catch(error => {
        console.log("发生错误！", error);
    });
    
  },
  methods: {
    openPageFun(path) {
        this.$router.push({
          path:path,
        })
    }
  }
};
</script>

<style>
</style>